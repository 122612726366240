/* eslint-disable no-undef, class-methods-use-this, prefer-destructuring, no-param-reassign */

import intlTelInput from "intl-tel-input";

class Forms {
	constructor() {
		this.init();
	}

	init() {}

	newForm(form) {
		this.formPrivacyPolicy = document.getElementById("input-form");
		this.formSendButton = document.getElementsByClassName("contact-button")[0];
		this.closeNotificationButton =
			document.getElementById("close-notification");

		// Load Re-captcha V3 or throw an error
		if (googleRecaptchaWebKey) {
			// eslint-disable-line
			const script = document.createElement("script");
			script.setAttribute("type", "text/javascript");
			script.setAttribute(
				"src",
				`https://www.google.com/recaptcha/api.js?render=${googleRecaptchaWebKey}`
			); // eslint-disable-line
			document.getElementsByTagName("head")[0].appendChild(script);

			// Load international telephone input
			this.loadPhoneInput(form);

			// Make sure the privacy policy value is unchecked
			this.formPrivacyPolicy.checked = false;
			this.formPrivacyPolicy.value = "unchecked";

			// Listen for privacy policy checkbox changes
			this.formPrivacyPolicy.addEventListener(
				"change",
				this.privacyPolicyCheckboxToogle
			);

			// Prevent form submit
			form.addEventListener("submit", this.preventFormSubmit);

			// Form validations and submit
			this.formSendButton.addEventListener("click", this.submitValidateForm);

			// Close notificacion banner
			this.closeNotificationButton.addEventListener(
				"click",
				this.closeNotificationBanner
			);

			// Enable submit button
			this.formSendButton.disabled = false;
		} else {
			console.error("Set up the Google ReCaptcha V3 keys"); // eslint-disable-line no-console
		}
	}

	loadPhoneInput(form) {
		const input = document.querySelector("#phone");
		const countryData = window.intlTelInputGlobals.getCountryData();
		const phoneNumber = form.querySelectorAll("[name=phone_number]")[0];
		let currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line

		if (countryData.length > 0) {
			for (let i = 0; i < countryData.length; i += 1) {
				const country = countryData[i];
				country.name = country.name.replace(/.+\((.+)\)/, "$1");
			}
		}

		if (currentLanguage === "en") {
			currentLanguage = "gb";
		} else if (currentLanguage === "ca") {
			currentLanguage = "es";
		}

		if (phoneNumber) {
			intlTelInput(input, {
				// Any initialisation options go here. Check https://github.com/jackocnr/intl-tel-input
				separateDialCode: "true",
				utilsScript: `https://${window.location.hostname}/wp-content/themes/anell/js/vendor/utils.js`,
				autoPlaceholder: "aggressive",
				initialCountry: `${currentLanguage}`,
				preferredCountries: [""],
			});
		}
	}

	privacyPolicyCheckboxToogle(privacyPolicyCheckbox) {
		if (privacyPolicyCheckbox.srcElement.value === "unchecked") {
			privacyPolicyCheckbox.srcElement.value = "checked";
		} else {
			privacyPolicyCheckbox.srcElement.value = "unchecked";
		}
	}

	preventFormSubmit(evt) {
		evt.preventDefault();
	}

	submitValidateForm(evt) {
		// Get the form parameters
		const action = "contact_form";
		const receiverEmail = this.form.querySelectorAll(
			"[name=receiver_email]"
		)[0];
		const languageEmail = this.form.querySelectorAll(
			"[name=language_email]"
		)[0];
		const firstName = this.form.querySelectorAll("[name=first_name]")[0];
		const lastName = this.form.querySelectorAll("[name=last_name]")[0];
		const id = this.form.querySelectorAll("[name=id]")[0];
		const email = this.form.querySelectorAll("[name=email]")[0];
		const phoneNumber = this.form.querySelectorAll("[name=phone_number]")[0];
		const address = this.form.querySelectorAll("[name=address]")[0];
		const number = this.form.querySelectorAll("[name=number]")[0];
		const block = this.form.querySelectorAll("[name=block]")[0];
		const stair = this.form.querySelectorAll("[name=stair]")[0];
		const floor = this.form.querySelectorAll("[name=floor]")[0];
		const door = this.form.querySelectorAll("[name=door]")[0];
		const postalCode = this.form.querySelectorAll("[name=postal_code]")[0];
		const town = this.form.querySelectorAll("[name=town]")[0];
		const reason = this.form.querySelectorAll("[name=reason]")[0];
		const file = this.form.querySelectorAll("[name=attachment_file]")[0];
		const message = this.form.querySelectorAll("[name=message]")[0];
		const messageIsRequired = this.form.querySelectorAll(
			"[name=user_message_required]"
		)[0];
		const privacyPolicy = this.form.querySelectorAll(
			"[name=privacy_policy]"
		)[0];
		const successMessage = this.form.querySelectorAll(
			"[name=success_message]"
		)[0];
		const errorMessage = this.form.querySelectorAll("[name=error_message]")[0];
		const notificationsBanner = document.getElementById("notification-banner");
		const notificationMessage =
			document.getElementsByClassName("notification-text")[0];
		const currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line
		const defaultLanguage = encodeURI(scriptsAjaxVariables.default_language); // eslint-disable-line
		const formData = new FormData();
		formData.append("action", action);

		if (languageEmail) {
			formData.append("language_email", languageEmail.value);
		}

		if (receiverEmail) {
			formData.append("receiver_email", receiverEmail.value);
		}

		if (firstName) {
			formData.append("first_name", firstName.value);
		}

		if (lastName) {
			formData.append("last_name", lastName.value);
		}

		if (id) {
			formData.append("id", id.value);
		}

		if (email) {
			formData.append("email", email.value);
		}

		if (phoneNumber) {
			formData.append("phone_number", phoneNumber.value);
		}

		if (address) {
			formData.append("address", address.value);
		}

		if (number) {
			formData.append("number", number.value);
		}

		if (block) {
			formData.append("block", block.value);
		}

		if (stair) {
			formData.append("stair", stair.value);
		}

		if (floor) {
			formData.append("floor", floor.value);
		}

		if (door) {
			formData.append("door", door.value);
		}

		if (postalCode) {
			formData.append("postal_code", postalCode.value);
		}

		if (town) {
			formData.append("town", town.value);
		}

		if (reason) {
			formData.append("reason", reason.value);
		}

		if (file) {
			formData.append("attachment_file", file.files[0]);
		}

		if (message) {
			formData.append("message", message.value);
		}

		if (messageIsRequired) {
			formData.append("user_message_required", messageIsRequired.value);
		}

		if (privacyPolicy) {
			formData.append("privacy_policy", privacyPolicy.value);
		}

		if (currentLanguage) {
			formData.append("current_language", currentLanguage);
		}

		if (defaultLanguage) {
			formData.append("default_language", defaultLanguage);
		}

		// Disable and animate the submit button
		evt.srcElement.disabled = true;
		evt.srcElement.classList.add("validating");

		// Recaptcha validation
		grecaptcha.ready(() => {
			// eslint-disable-line
			grecaptcha.execute(googleRecaptchaWebKey, { action }).then((token) => {
				// eslint-disable-line
				if (token) {
					formData.append("token", token);
				}

				// Ajax form submit
				const xmlhttp = window.ajaxReq();
				const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
				xmlhttp.open("POST", url, true); // set true for async, false for sync request
				xmlhttp.send(formData); // or null, if no parameters are passed

				// Validation
				xmlhttp.onreadystatechange = () => {
					if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
						try {
							const data = JSON.parse(xmlhttp.responseText);

							// Hide previous errors
							firstName.classList.remove("show-error");
							lastName.classList.remove("show-error");
							email.classList.remove("show-error");
							privacyPolicy.classList.remove("show-error");

							if (phoneNumber) {
								phoneNumber.classList.remove("show-error");
							}

							if (id) {
								id.classList.remove("show-error");
							}

							if (address) {
								address.classList.remove("show-error");
							}

							if (number) {
								number.classList.remove("show-error");
							}

							if (postalCode) {
								postalCode.classList.remove("show-error");
							}

							if (town) {
								town.classList.remove("show-error");
							}

							if (reason) {
								reason.classList.remove("show-error");
							}

							if (message) {
								message.classList.remove("show-error");
							}

							if (file) {
								file.classList.remove("show-error");
							}

							if (data.validation === false) {
								// Show errors
								if (data.first_name) {
									firstName.classList.add("show-error");
								}
								if (data.last_name) {
									lastName.classList.add("show-error");
								}
								if (data.email) {
									email.classList.add("show-error");
								}
								if (data.phone_number) {
									phoneNumber.classList.add("show-error");
								}
								if (data.id) {
									id.classList.add("show-error");
								}
								if (data.address) {
									address.classList.add("show-error");
								}
								if (data.number) {
									number.classList.add("show-error");
								}
								if (data.postal_code) {
									postalCode.classList.add("show-error");
								}
								if (data.town) {
									town.classList.add("show-error");
								}
								if (data.reason) {
									reason.classList.add("show-error");
								}
								if (data.message) {
									message.classList.add("show-error");
								}
								if (data.file) {
									file.classList.add("show-error");
								}
								if (data.privacy_policy) {
									privacyPolicy.classList.add("show-error");
								}

								// Scroll to the first error.
								const elementsWithError =
									evt.srcElement.form.getElementsByClassName("show-error");
								elementsWithError[0].scrollIntoView({
									block: "center",
									behavior: "smooth",
								});
							} else if (data.success === false) {
								// Error notification message
								notificationMessage.innerHTML = errorMessage.value;

								// Show notification
								notificationsBanner.classList.add("active");
							} else {
								// Success notification message
								notificationMessage.innerHTML = successMessage.value;

								// Clear the form
								evt.srcElement.form.reset();

								// Show notification
								notificationsBanner.classList.add("active");
							}

							// Enable and stop the submit button animation
							evt.srcElement.disabled = false;
							evt.srcElement.classList.remove("validating");
						} catch (error) {
							// Show notification
							notificationsBanner.classList.add("active");
						}
					}
				};
			});
		});
	}

	closeNotificationBanner(evt) {
		evt.preventDefault();
		evt.srcElement.parentNode.classList.remove("active");
	}
}

export default Forms;
