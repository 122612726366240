/* eslint-disable */
import Swiper, { Navigation } from "swiper";

class SliderCards {
	constructor() {
		this.cardsSliderSections = document.getElementsByClassName("cards-slider");
		if (this.cardsSliderSections[0]) {
			Swiper.use([Navigation]);
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		Array.from(this.cardsSliderSections).forEach((section) =>
			this.initSlider(section)
		);
	}

	initSlider(section) {
		this.swiperContainer = section.querySelector(".swiper-container");
		this.swiperButtonPrev = section.querySelector(".swiper-button-prev");
		this.swiperButtonNext = section.querySelector(".swiper-button-next");
		this.swiperInstance = new Swiper(this.swiperContainer, {
			grabCursor: true,
			slidesPerView: "auto",
			spaceBetween: 24,

			// Responsive
			breakpoints: {
				992: {
					slidesPerView: 2.5,
				},
				1070: {
					slidesPerView: "auto",
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: this.swiperButtonNext,
				prevEl: this.swiperButtonPrev,
			},

			// disable if desktop && less than 4 cards

			on: {
				init: function () {
					const swiper = document.querySelector(".swiper-container").swiper;
					const swiperContainer = swiper.wrapperEl.parentElement;

					const disableSwiper = () => {
						swiper.disable();
						document.querySelector(".swiper-button-prev").style.visibility =
							"hidden";
						document.querySelector(".swiper-button-next").style.visibility =
							"hidden";
						if (
							swiperContainer.classList.contains("swiper-container-disabled") ==
							false
						) {
							swiperContainer.classList.add("swiper-container-disabled");
						}
					};

					const enableSwiper = () => {
						swiper.enable();
						document.querySelector(".swiper-button-prev").style.visibility =
							"visible";
						document.querySelector(".swiper-button-next").style.visibility =
							"visible";
						if (
							swiperContainer.classList.contains("swiper-container-disabled")
						) {
							swiperContainer.classList.remove("swiper-container-disabled");
						}
					};

					const getWidth = () => {
						var w = window.innerWidth;

						if (w >= 1210 && swiper.slides.length === 4) {
							disableSwiper();

							if (
								swiperContainer.classList.contains(
									"swiper-container-4-items"
								) == false
							) {
								swiperContainer.classList.add("swiper-container-4-items");
							}
						} else if (w >= 1000 && swiper.slides.length === 3) {
							disableSwiper();
						} else if (w >= 700 && swiper.slides.length === 2) {
							disableSwiper();
						} else {
							enableSwiper();
						}
					};

					window.addEventListener("resize", getWidth);
					getWidth();

					// hover cards gif

					const imgGif = swiper.imagesToLoad;

					for (var i = 0; i < imgGif.length; i++) {
						if (imgGif[i].classList.contains("simple-card__image-animation")) {
							function mouseOver() {
								const simpleCardImageAnimation =
									this.children[0].lastElementChild;
								simpleCardImageAnimation.src =
									simpleCardImageAnimation.getAttribute("data-src");
							}
							function mouseOut() {
								const simpleCardImageAnimation =
									this.children[0].lastElementChild;

								// data:image/png;base64... -> png 1x1px transparent

								simpleCardImageAnimation.src =
									"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAKSURBVHicY2AAAAACAAFIr6RxAAAAAElFTkSuQmCC";
							}

							imgGif[i].parentElement.parentElement.addEventListener(
								"mouseover",
								mouseOver
							);
							imgGif[i].parentElement.parentElement.addEventListener(
								"mouseout",
								mouseOut
							);
						}
					}
				},
				/* eslint-enable */
			},
		});
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */
}

export default SliderCards;
