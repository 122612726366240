/* eslint-disable no-undef, class-methods-use-this, prefer-destructuring, no-param-reassign */

import Forms from "../../lib/forms";

class JobOffersForm extends Forms {
	init() {
		this.firstForm = document.getElementById("job-offers-form");
		if (this.firstForm) {
			this.newForm(this.firstForm);
		}

		const fileInput = document.getElementById("attachment-file");
		const fileSelect = document.getElementsByClassName(
			"form__file-container"
		)[0];

		if (fileSelect !== undefined) {
			fileSelect.onclick = function () {
				fileInput.onchange = function () {
					const filename = fileInput.files[0].name;
					const selectName = document.getElementsByClassName("file-select")[0];
					selectName.innerText = filename;
				};
			};
		}
	}

	submitValidateForm(evt) {
		// Get the form parameters
		const action = "job_offers_form";
		const receiverEmail = this.form.querySelectorAll(
			"[name=receiver_email]"
		)[0];
		const languageEmail = this.form.querySelectorAll(
			"[name=language_email]"
		)[0];
		const jobTitle = this.form.querySelectorAll("[name=job_title]")[0];
		const jobLocalitat = this.form.querySelectorAll("[name=job_localitat]")[0];

		const firstName = this.form.querySelectorAll("[name=first_name]")[0];
		const lastName = this.form.querySelectorAll("[name=last_name]")[0];
		const email = this.form.querySelectorAll("[name=email]")[0];
		const phoneNumber = this.form.querySelectorAll("[name=phone_number]")[0];
		const experience = this.form.querySelectorAll("[name=experience]")[0];
		const file = this.form.querySelectorAll("[name=attachment_file]")[0];

		const privacyPolicy = this.form.querySelectorAll(
			"[name=privacy_policy]"
		)[0];
		/*
		const successMessage = this.form.querySelectorAll(
			"[name=success_message]"
		)[0];
		*/
		const errorMessage = this.form.querySelectorAll("[name=error_message]")[0];
		const okPage = this.form.querySelectorAll("[name=ok_page]")[0];

		const notificationsBanner = document.getElementById("notification-banner");
		const notificationMessage =
			document.getElementsByClassName("notification-text")[0];
		const currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line
		const defaultLanguage = encodeURI(scriptsAjaxVariables.default_language); // eslint-disable-line
		const formData = new FormData();
		formData.append("action", action);

		if (languageEmail) {
			formData.append("language_email", languageEmail.value);
		}

		if (receiverEmail) {
			formData.append("receiver_email", receiverEmail.value);
		}

		if (jobTitle) {
			formData.append("job_title", jobTitle.value);
		}
		if (jobLocalitat) {
			formData.append("job_localitat", jobLocalitat.value);
		}

		if (firstName) {
			formData.append("first_name", firstName.value);
		}

		if (lastName) {
			formData.append("last_name", lastName.value);
		}

		if (email) {
			formData.append("email", email.value);
		}

		if (phoneNumber) {
			formData.append("phone_number", phoneNumber.value);
		}

		if (experience) {
			formData.append("experience", experience.value);
		}

		if (file) {
			formData.append("attachment_file", file.files[0]);
		}

		if (privacyPolicy) {
			formData.append("privacy_policy", privacyPolicy.value);
		}

		if (currentLanguage) {
			formData.append("current_language", currentLanguage);
		}

		if (defaultLanguage) {
			formData.append("default_language", defaultLanguage);
		}

		// Disable and animate the submit button
		evt.srcElement.disabled = true;
		evt.srcElement.classList.add("validating");

		// Recaptcha validation
		grecaptcha.ready(() => {
			// eslint-disable-line
			grecaptcha.execute(googleRecaptchaWebKey, { action }).then((token) => {
				// eslint-disable-line
				if (token) {
					formData.append("token", token);
				}

				// Ajax form submit
				const xmlhttp = window.ajaxReq();
				const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
				xmlhttp.open("POST", url, true); // set true for async, false for sync request
				xmlhttp.send(formData); // or null, if no parameters are passed

				// Validation
				xmlhttp.onreadystatechange = () => {
					if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
						try {
							const data = JSON.parse(xmlhttp.responseText);

							// Hide previous errors
							firstName.classList.remove("show-error");
							lastName.classList.remove("show-error");
							email.classList.remove("show-error");
							privacyPolicy.classList.remove("show-error");

							if (phoneNumber) {
								phoneNumber.classList.remove("show-error");
							}

							if (experience) {
								experience.classList.remove("show-error");
							}

							if (file) {
								file.classList.remove("show-error");
							}

							if (data.validation === false) {
								// Show errors
								if (data.first_name) {
									firstName.classList.add("show-error");
								}
								if (data.last_name) {
									lastName.classList.add("show-error");
								}
								if (data.email) {
									email.classList.add("show-error");
								}
								if (data.phone_number) {
									phoneNumber.classList.add("show-error");
								}
								if (data.experience) {
									experience.classList.add("show-error");
								}

								if (data.file) {
									file.classList.add("show-error");
								}
								if (data.privacy_policy) {
									privacyPolicy.classList.add("show-error");
								}
								if (data.captcha) {
									// TODO improve this message, maybe
									console.log("Captcha failed");
								}

								// Scroll to the first error.
								const elementsWithError =
									evt.srcElement.form.getElementsByClassName("show-error");
								elementsWithError[0].scrollIntoView({
									block: "center",
									behavior: "smooth",
								});
							} else if (data.success === false) {
								// Error notification message
								notificationMessage.innerHTML = errorMessage.value;

								// Show notification
								notificationsBanner.classList.add("active");
							} else {
								// Redirect to OK page
								window.location = okPage.value;
								return;
							}

							// Enable and stop the submit button animation
							evt.srcElement.disabled = false;
							evt.srcElement.classList.remove("validating");
						} catch (error) {
							// Show notification
							console.log(error);
							notificationMessage.innerHTML = errorMessage.value;
							notificationsBanner.classList.add("active");

							evt.srcElement.disabled = false;
							evt.srcElement.classList.remove("validating");
						}
					} else {
						// Show error notification
						evt.srcElement.disabled = false;
						evt.srcElement.classList.remove("validating");

						notificationMessage.innerHTML = errorMessage.value;
						notificationsBanner.classList.add("active");
					}
				};
			});
		});
	}
}

export default JobOffersForm;
