/* eslint-disable class-methods-use-this, prefer-destructuring, no-use-before-define, no-shadow */
class Header {
	constructor() {
		this.initHeader();
	}

	initHeader() {
		const header = document.getElementById("header");
		const headerHeight = header.offsetHeight + 60;
		const subMenus = header.querySelectorAll(".sub-menu");
		const goBackBttn = header.querySelector(".menu__go-back--bttn");
		const texGgoBackBttn = goBackBttn.textContent;
		const menuItems = header.querySelectorAll(
			"	#menu-cabecera-izquierda .menu-item"
		);
		let distanceToTop = document.documentElement.scrollTop;

		// Menu button event listener
		const mobileMenuToogler = document.getElementById("main-menu-toggler");
		if (mobileMenuToogler) {
			mobileMenuToogler.addEventListener("click", blockScroll);
		}

		// Scroll event listener
		window.addEventListener("scroll", throttle(callback, 200));

		if (distanceToTop > 0) {
			header.classList.add("scrolled");
		}

		function blockScroll() {
			const body = document.body;
			if (!this.classList.contains("menu-item-has-children")) {
				if (body.classList.contains("u-mobile-block-scroll")) {
					document.body.classList.remove("u-mobile-block-scroll");
				} else {
					document.body.classList.add("u-mobile-block-scroll");
				}
			}
		}

		function throttle(callback, wait = 100) {
			let timer = null;

			return (args) => {
				if (timer === null) {
					timer = setTimeout(() => {
						callback.apply(this, args);
						timer = null;
					}, wait);
				}
			};
		}

		function callback() {
			const newDistanceToTop = document.documentElement.scrollTop;

			if (newDistanceToTop > headerHeight) {
				header.classList.add("scrolled");
			}

			if (newDistanceToTop < distanceToTop) {
				header.classList.add("scrolled--show");
			} else {
				header.classList.remove("scrolled--show");
			}

			if (newDistanceToTop === 0) {
				setTimeout(() => {
					header.classList.remove("scrolled", "scrolled--show");
				}, 1000);
			}

			distanceToTop = newDistanceToTop;
		}

		// Set a CSS variable to fix Webkit-based mobile phones handling of vh units.
		// You can use it with the vh() SASS function.
		function setCSSvh() {
			document.documentElement.style.setProperty(
				"--vh",
				`${window.innerHeight}px`
			);
		}

		window.addEventListener("resize", setCSSvh);
		window.addEventListener("orientationchange", setCSSvh);
		setCSSvh();

		// menu mobile

		if (subMenus !== null) {
			try {
				// menu mobile submenu

				subMenus.forEach((subMenu) => {
					const newBttn = document.createElement("li");
					const textParentNode = document.createElement("li");
					const linkLi = subMenu.parentNode;
					linkLi.firstChild.addEventListener("click", () => {
						linkLi.classList.add("active");
					});
					newBttn.innerText = texGgoBackBttn;
					textParentNode.innerText = linkLi.firstChild.innerText;
					newBttn.classList.add("sub-menu__goBack-bttn");
					textParentNode.classList.add("sub-menu__title");
					subMenu.prepend(newBttn);
					subMenu.prepend(textParentNode);
					newBttn.addEventListener("click", () => {
						newBttn.parentNode.parentNode.classList.remove("active");
					});

					// resize

					const getWidth = () => {
						const w = window.innerWidth;
						const newBttnClass = subMenu.querySelector(
							".sub-menu__goBack-bttn"
						);
						const textParentNodeClass =
							subMenu.querySelector(".sub-menu__title");

						if (w <= 1440) {
							if (
								newBttnClass.style.display === "none" &&
								textParentNodeClass.style.display === "none"
							) {
								newBttnClass.remove();
								textParentNodeClass.remove();
							}
						}
					};

					window.addEventListener("resize", getWidth);
					getWidth();
				});
			} catch (err) {
				console.log(err.message);
			}

			goBackBttn.remove();
		}

		// clone languages

		const menuRight = header.querySelector(".header__menu-container--right");

		if (menuRight !== null) {
			try {
				const menuRightCloned = menuRight.cloneNode(true);
				header.appendChild(menuRightCloned);
				menuRightCloned.className = "header__menu-container--right__outer";
			} catch (err) {
				console.log(err.message);
			}
		}

		// remove transitions header resize window

		const classes = header.classList;
		let timer = 0;
		window.addEventListener("resize", function () {
			if (timer) {
				clearTimeout(timer);
				timer = null;
			} else classes.add("stop-transitions");

			timer = setTimeout(() => {
				classes.remove("stop-transitions");
				timer = null;
			}, 100);
		});

		// addClass menu-item if has sub-menu

		if (menuItems !== null) {
			menuItems.forEach((menuItem) => {
				if (menuItem.lastElementChild.className === "sub-menu") {
					menuItem.classList.add("menu-item-has-submenu");
				}
			});
		}
	}
}

export default Header;
