import Swiper from "swiper/bundle";

class Clients {
	constructor() {
		this.clientsSections = document.getElementsByClassName("clients");
		if (this.clientsSections[0]) {
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		Array.from(this.clientsSections).forEach((section) =>
			this.initSlider(section)
		);
	}

	initSlider(section) {
		const sliderContainer = section.querySelector(".swiper-container");
		const slider = new Swiper(sliderContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: true,
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
			},
			slidesPerView: 2,
			speed: 3000,
			spaceBetween: 30,
			grabCursor: true,
			mousewheelControl: true,
			preloadImages: true,
			keyboardControl: true,
			breakpoints: {
				769: {
					slidesPerView: 3,
					spaceBetweenSlides: 1,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: ".js-swiper-next",
				prevEl: ".js-swiper-prev",
			},
		});
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */
}

export default Clients;
