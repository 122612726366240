/* eslint-disable class-methods-use-this */

// Import libraries
import LazyLoading from "./lib/lazy-loading";
import LottieLoader from "./lib/lottie";

// Import components
import Cta from "./template-parts/components/cta";
import Filters from "./template-parts/components/filters";
import Pagination from "./template-parts/components/pagination";

// Import sections
import Accordion from "./template-parts/sections/accordion";
import Clients from "./template-parts/sections/clients";
import ContactForm from "./template-parts/sections/contact-form";
import JobOffersForm from "./template-parts/sections/job-offers-form";
import CustomersFeedback from "./template-parts/sections/customers-feedback";
import Facts from "./template-parts/sections/facts";
import Hero from "./template-parts/sections/hero";
import Map from "./template-parts/sections/map";
import SliderCards from "./template-parts/sections/slider-cards";
import SliderGallery from "./template-parts/sections/slider-gallery";

export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		// Load libraries
		window.lazyLoading = new LazyLoading();
		window.lottieLoader = new LottieLoader();

		// Load components
		window.cta = new Cta();
		window.filters = new Filters();
		window.pagination = new Pagination();

		// Load sections
		window.clients = new Clients();
		window.contactForm = new ContactForm();
		window.jobOffersForm = new JobOffersForm();
		window.customersFeedback = new CustomersFeedback();
		window.facts = new Facts();
		window.hero = Hero();
		window.hero = Accordion();
		window.map = new Map();
		window.sliderCards = new SliderCards();
		window.sliderGallery = new SliderGallery();
	}
}
