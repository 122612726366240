/* eslint-disable no-undef, class-methods-use-this, prefer-destructuring, no-param-reassign */

import Forms from "../../lib/forms";

class ContactForm extends Forms {
	init() {
		this.firstForm = document.getElementById("contact-form");
		if (this.firstForm) {
			this.newForm(this.firstForm);
		}

		const fileInput = document.getElementById("attachment-file");
		const fileSelect = document.getElementsByClassName(
			"form__file-container"
		)[0];

		if (fileSelect !== undefined) {
			fileSelect.onclick = function () {
				fileInput.onchange = function () {
					const filename = fileInput.files[0].name;
					const selectName = document.getElementsByClassName("file-select")[0];
					selectName.innerText = filename;
				};
			};
		}
	}
}

export default ContactForm;
