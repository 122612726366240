/* eslint-disable */
const Hero = () => {
	const hero = document.getElementsByClassName("hero");

	if (hero[0]) {
		const scrollBottom = document.querySelector(".hero__arrow");
		const section = document.querySelector("section");
		const height = section.offsetHeight;

		function scrollToSmoothly(pos, time) {
			var currentPos = window.pageYOffset;
			var start = null;
			window.requestAnimationFrame(function step(currentTime) {
				start = !start ? currentTime : start;
				var progress = currentTime - start;
				if (currentPos < pos) {
					window.scrollTo(
						0,
						((pos - currentPos) * progress) / time + currentPos
					);
				} else {
					window.scrollTo(
						0,
						currentPos - ((currentPos - pos) * progress) / time
					);
				}
				if (progress < time) {
					window.requestAnimationFrame(step);
				} else {
					window.scrollTo(0, pos);
				}
			});
		}

		// Scroll button.
		if (scrollBottom !== null) {
			scrollBottom.addEventListener("click", function () {
				scrollToSmoothly(height, 300);
			});
		}
	}
};

export default Hero;
