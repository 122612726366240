/* eslint-disable */

const Accordion = () => {
	const accordion = document.getElementsByClassName("accordion");

	function addId(item, index) {
		item.setAttribute("id", "accordion-title-" + (index + 1));
	}

	if (accordion[0]) {
		const accordionTitles = document.querySelectorAll(".accordion__title");
		const tagNameA = document.getElementsByTagName("a");

		if (accordionTitles !== null) {
			accordionTitles.forEach(addId);
		}

		// scroll

		// https://codepen.io/mary_pieroszkiewicz/pen/rdQmLw

		const list = document.getElementsByClassName("menu-list")[0];

		// easing functions http://goo.gl/5HLl8
		Math.easeInOutQuad = function (t, b, c, d) {
			t /= d / 2;
			if (t < 1) {
				return (c / 2) * t * t + b;
			}
			t--;
			return (-c / 2) * (t * (t - 2) - 1) + b;
		};

		Math.easeInCubic = function (t, b, c, d) {
			var tc = (t /= d) * t * t;
			return b + c * tc;
		};

		Math.inOutQuintic = function (t, b, c, d) {
			var ts = (t /= d) * t,
				tc = ts * t;
			return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
		};

		// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
		var requestAnimFrame = (function () {
			return (
				window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				function (callback) {
					window.setTimeout(callback, 1000 / 60);
				}
			);
		})();

		function scrollTo(to, callback, duration) {
			function move(amount) {
				document.documentElement.scrollTop = amount;
				document.body.parentNode.scrollTop = amount;
				document.body.scrollTop = amount;
			}

			function position() {
				return (
					document.documentElement.scrollTop ||
					document.body.parentNode.scrollTop ||
					document.body.scrollTop
				);
			}
			var start = position(),
				change = to - start,
				currentTime = 0,
				increment = 20;
			duration = typeof duration === "undefined" ? 500 : duration;
			var animateScroll = function () {
				// increment the time
				currentTime += increment;
				// find the value with the quadratic in-out easing function
				var val = Math.easeInOutQuad(currentTime, start, change, duration);
				// move the document.body
				move(val);
				// do the animation unless its over
				if (currentTime < duration) {
					requestAnimFrame(animateScroll);
				} else {
					if (callback && typeof callback === "function") {
						// the animation is done so lets callback
						callback();
					}
				}
			};
			animateScroll();
		}

		Array.prototype.slice
			.call(document.querySelectorAll(".accordion__content a"))
			.forEach((item) => {
				const target = item.getAttribute("href").slice(1);

				item.addEventListener("click", (e) => {
					const { target } = e;
					const to = target.getAttribute("href").slice(1);
					const element = document.getElementById(to);
					const bodyRect = document.body.getBoundingClientRect().top;
					const elemRect = element.getBoundingClientRect();
					const offset = elemRect.top - bodyRect;
					scrollTo(offset, null, 300);
				});
			});

		// active

		const toggleItem = (elem) => {
			for (let i = 0; i < elem.length; i += 1) {
				elem[i].addEventListener("click", function (e) {
					if (this.classList.contains("active") === true) {
						this.classList.remove("active");
					} else {
						this.classList.add("active");
					}
					e.preventDefault();
				});
			}
		};
		toggleItem(document.querySelectorAll(".accordion__label"));
	}
};

export default Accordion;
