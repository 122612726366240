import "./scss/style.scss";
import ScrollMagic from "scrollmagic";
import Header from "./js/template-parts/common/header";
import ModulesLoader from "./js/modules-loader";
import Barba from "./js/lib/barba";
import Cookies from "./js/template-parts/popups/cookies";
import DisableScroll from "./js/lib/disable-scroll";

// Load objects
document.addEventListener("DOMContentLoaded", () => {
	// Header
	window.header = new Header();

	// BarbaJS
	window.barba = new Barba();
});

window.addEventListener("load", () => {
	// Ajax requests
	window.ajaxReq = function ajaxReq() {
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest(); // eslint-disable-line
		}

		if (window.ActiveXObject) {
			return new ActiveXObject("Microsoft.XMLHTTP"); // eslint-disable-line no-console
		}

		console.log("Browser does not support XMLHTTP."); // eslint-disable-line no-console
		return false;
	};

	// Set animations
	window.setAnimationScenes = function setAnimationScenes(
		scrollMagicController
	) {
		const animatableElements = [
			".cards-slider",
			".facts",
			".featured-content",
			".featured-links",
			".grid-card",
			".hero",
			".team",
			".separator",
		];
		// Set animation classes to all the elements that will use them.
		/* eslint-disable no-restricted-syntax */
		for (const el of Array.from(
			document.querySelectorAll(animatableElements.join(","))
		)) {
			new ScrollMagic.Scene({
				triggerElement: el,
				triggerHook: 0.75,
				reverse: false,
			})
				.setClassToggle(el, "animated")
				.addTo(scrollMagicController);
		}
		/* eslint-enable no-restricted-syntax */
	};

	// Load JS scripts
	window.loadJS = function loadJS(file, callback) {
		// DOM: Create the script element
		const jsElm = document.createElement("script");
		// set the type attribute
		jsElm.type = "application/javascript";
		// make the script element load file
		jsElm.src = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	};

	// Load CSS styles
	window.loadCSS = function loadJS(file, callback) {
		// DOM: Create the script element
		const jsElm = document.createElement("link");
		// set the rel attribute
		jsElm.rel = "stylesheet";
		// set the media attribute
		jsElm.media = "all";
		// make the script element load file
		jsElm.href = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	};

	// Modules loader
	window.modulesLoader = new ModulesLoader();
	window.modulesLoader.loadModules();

	// Disable scroll
	window.disableScroll = new DisableScroll();

	// Cookies banner
	window.cookies = new Cookies();

	// Animations
	window.setAnimationScenes(new ScrollMagic.Controller());
});
